import request from '@/utils/http';

export function dealDetails_api() {
  return request({
    url: '/v7/user/sales',
    method: 'get'
  })
}
// 公共配置
export function getConfig_api(data) {
  return request({
    url: '/v4/common/config',
    method: 'post',
    data: data
  })
}

// 上架股份
export function payDeal_api(data) {
  return request({
    url: '/v7/user/sharesSales',
    method: 'post',
    data: data
  })
}

// 股份列表
export function dealList_api(data) {
  return request({
    url: '/v7/user/sharesSales',
    method: 'get',
    params: data
  })
}

// 取消售卖
export function cancelSale_api(id) {
  return request({
    url: '/v7/user/sharesSalesCancel/' + id,
    method: 'put',
  })
}

// 抢拍
export function Auction_api(data) {
  return request({
    url: '/v7/user/sharesTrade',
    method: 'post',
    data: data
  })
}

// 修改工会信息
export function detailsInfo_api(data) {
  return request({
    url: '/v6/user/user',
    method: 'post',
    data: data
  })
}

// 订单列表
export function getOrderList_api(data) {
  return request({
    url: '/v7/user/sharesTrade',
    method: 'get',
    params:data
  })
}

// 删除订单
export function deleteOrder_api(id) {
  return request({
    url: '/v7/user/sharesTrade/'+id,
    method: 'delete',
  })
}

// 兑换配置
export function conversionConfig_api() {
  return request({
    url: '/v6/user/sharesApplyConfig',
    method: 'get',

  })
}

// 兑换申請
export function conversionSunmit_api(data) {
  return request({
    url: '/v6/user/sharesApply',
    method: 'post',
    data

  })
}

// 兑换详情
export function conversionDetails_api(id) {
  return request({
    url: '/v6/user/sharesApply/'+id,
    method: 'get',
  })
}

// 兑换列表
export function conversionList_api(data) {
  return request({
    url: '/v6/user/sharesApply',
    method: 'get',
    data
  })
}

// 删除股份售卖
export function dealPut_api(id) {
  return request({
    url: '/v7/user/sharesSales/'+id,
    method: 'delete',
  })
}

// 股份转让支付
export function dealpay_api(data,id) {
  return request({
    url: '/v7/user/sharesTradeTax/'+id,
    method: 'put',
    data
  })
}

// 股份转让支付
export function gathering_api(data,id) {
  return request({
    url: '/v7/user/sharesSalesReceipt/'+id,
    method: 'put',
    data
  })
}

// 受让转让支付
export function transfer_api(data,id) {
  return request({
    url: '/v7/user/sharesTradeRemit/'+id,
    method: 'put',
    data
  })
}
// 股份转让支付
export function putDeal_api(data,id) {
  return request({
    url: '/v7/user/sharesTradeInfo/'+id,
    method: 'put',
    data
  })
}

// 售卖详情
export function SellingDeal_api(id) {
  return request({
    url: '/v7/user/sharesSales/'+id,
    method: 'get',
  })
}

// 售卖详情
export function dealFromList_api(data) {
  return request({
    url: '/v6/user/contract',
    method: 'get',
    params: data
  })
}

// 售卖详情
export function returnList_api(data,id) {
  return request({
    url: '/v7/user/sharesSalesReturn/'+id,
    method: 'put',
    data
  })
}

// 余额列表
export function withdrawal_api(data) {
  return request({
    url: '/v6/user/salesLog',
    method: 'get',
    params: data
  })
}

// 余额列表
export function getWithdrawal_api(data) {
  return request({
    url: '/v6/user/salesCash',
    method: 'post',
    data: data
  })
}

// 提现列表
export function submitWithdrawal_api(data) {
  return request({
    url: '/v6/user/salesCash',
    method: 'get',
    params: data
  })
}

// 退款列表
export function returnorder_api(data) {
  return request({
    url: '/v6/user/refund',
    method: 'get',
    params: data
  })
}
// 转让退款列表
export function payReturnorder_api(data) {
  return request({
    url: '/v7/user/remitRefund',
    method: 'get',
    params: data
  })
}

// 股份明細
export function shareorder_api(data) {
  return request({
    url: '/v6/user/sharesLog',
    method: 'get',
    params: data
  })
}

// 股份明細
export function cloneOrder_api(id) {
  return request({
    url: '/v6/user/sharesTradeCancel/'+id,
    method: 'put',
  })
}

// 信息確認
export function confimMessagte(data,id) {
  return request({
    url: '/v7/user/sharesSalesInfo/'+id,
    method: 'put',
    data
  })
}

// 买家支付运费
export function payfreight(id) {
  return request({
    url: '/v7/user/sharesTradePay/'+id,
    method: 'put'
  })
}

// 卖家支付运费
export function sellerfreight(id) {
  return request({
    url: '/v7/user/sharesSalesPay/'+id,
    method: 'put'
  })
}

// 卖家支付运费
export function putOrder_api(id) {
  return request({
    url: '/v7/user/sharesTrade/'+id,
    method: 'get'
  })
}

// 退款凭证
export function returnProof(id,data) {
  return request({
    url: '/v7/user/remitRefund/'+id,
    method: 'put',
    data
  })
}

// 退款詳情
export function returnDetails_api(id) {
  return request({
    url: '/v7/user/remitRefund/'+id,
    method: 'get',

  })
}

// 地址添加
export function addAddress_api(data){
  return request({
    url: '/v7/user/address',
    method: 'post',
    data
  })
}

// 地址添加
export function listAddress_api(){
  return request({
    url: '/v7/user/address',
    method: 'get',
  })
}

// 地址詳情
export function getAddress_api(id){
  return request({
    url: `/v7/user/address/${id}`,
    method: 'get',
  })
}

// 地址编辑
export function detailAddress_api(id,data){
  return request({
    url: `/v7/user/address/${id}`,
    method: 'put',
    data
  })
}

// 地址刪除
export function deleteAddress_api(id){
  return request({
    url: `/v7/user/address/${id}`,
    method: 'DELETE',
  })
}
// 会员申请
export function vipapply_api(data){
  return request({
    url: `/v8/user/renew`,
    method: 'post',
    data
  })
}

// 申请详情
export function applyDetail_api(id){
  return request({
    url: `/v8/user/renew/${id}`,
    method: 'get'
  })
}

// 支付
export function payApplyDetail_api(data,id){
  return request({
    url: `/v8/user/renewPay/${id}`,
    method: 'put',
    data
  })
}


// 会员申请
export function shareapply_api(data){
  return request({
    url: `/v8/user/shareholderRenew`,
    method: 'post',
    data
  })
}

// 申请详情
export function shareDetail_api(id){
  return request({
    url: `/v8/user/shareholderRenew/${id}`,
    method: 'get'
  })
}

// 申请详情
export function shareList_api(data){
  return request({
    url: `/v8/user/shareholderRenew`,
    method: 'get',
    params: data
  })
}

// 支付
export function payShareDetail_api(data,id){
  return request({
    url: `/v8/user/shareholderRenewPay/${id}`,
    method: 'put',
    data
  })
}
// 支付
export function unionActivity_api(){
  return request({
    url: `/v7/user/unionActivity`,
    method: 'get',
  })
}

// 支付
export function renewList_api(data){
  return request({
    url: `/v8/user/renew`,
    method: 'get',
    params: data
  })
}

// goodsList
export function goodsList_api(data){
  return request({
    url: `/v7/user/goods`,
    method: 'get',
    params: data
  })
}

// goodsList
export function goodsDetails_api(id){
  return request({
    url: `/v7/user/goods/${id}`,
    method: 'get',
  })
}

// goodsList
export function goodsAccess_api(data){
  return request({
    url: `/v7/user/order/1`,
    method: 'put',
    data
  })
}

// goodsList
export function orderAccess_api(data){
  return request({
    url: `/v7/user/order`,
    method: 'post',
    data
  })
}

// goodsList
export function invoiceAccess_api(data){
  return request({
    url: `/v8/user/invoice`,
    method: 'get',
    params: data
  })
}

// goodsList
export function invoiceDetails_api(data){
  return request({
    url: `/v8/user/invoiceHeader`,
    method: 'post',
    data
  })
}

// goodsList
export function invoicesubmit_api(data){
  return request({
    url: `/v8/user/invoice`,
    method: 'post',
    data
  })
}

// goodsList
export function Activitysubmit_api(data){
  return request({
    url: `/v7/user/unionActivity`,
    method: 'post',
    data
  })
}

// goodsList
export function invoiceList(data){
  return request({
    url: `/v8/user/invoice`,
    method: 'get',
    params: data
  })
}

// goodsList
export function orderDetail_api(id,data){
  return request({
    url: `/v7/user/order/${id}`,
    method: 'get',
    params: data
  })
}

// goodsList
export function orderList_api(data){
  return request({
    url: `/v7/user/order`,
    method: 'get',
    params: data
  })
}

// goodsList
export function applyItem_api(id){
  return request({
    url: `/v6/user/sharesApply/${id}`,
    method: 'get',
  })
}

// goodsList
export function applyPut_api(id,data){
  return request({
    url: `/v6/user/sharesApply/${id}`,
    method: 'put',
    data
  })
}

// goodsList
export function applyList_api(data){
  return request({
    url: `/v7/user/unionActivity/1`,
    method: 'get',
    params: data
  })
}

// goodsList
export function activeDetails_api(id){
  return request({
    url: `/v7/user/unionActivity/${id}/edit`,
    method: 'get',
  })
}
// goodsList
export function applyDetails_api(id){
  return request({
    url: `/v7/user/unionActivity/${id}`,
    method: 'put',
  })
}

// goodsList
export function orderClone_api(id){
  return request({
    url: `/v7/user/order/${id}/edit`,
    method: 'get',
  })
}

// goodsList
export function orderDelete_api(id){
  return request({
    url: `/v7/user/order/${id}`,
    method: 'delete',
  })
}

// 立即支付
export function bugOrder_api(data){
  return request({
    url: `/v7/user/order/repayment`,
    method: 'POST',
    data
  })
}

// 取消退款
export function cloneReturn_api(id,data){
  return request({
    url: `/v7/user/orderRefund/${id}`,
    method: 'put',
    data
  })
}

// 取消退款
export function getActiveDeatil_api(id){
  return request({
    url: `/v7/user/unionActivity/${id}`,
    method: 'put',
  })
}

// 取消活动
export function cloneActiveDeatil_api(data){
  return request({
    url: `/v7/user/unionActivity/create`,
    method: 'post',
    data
  })
}


// 确认收货
export function deliveryDeatil_api(data){
  return request({
    url: `/v7/user/order/confirm`,
    method: 'post',
    data
  })
}

// 推荐人
export function referrerDeatil_api(data){
  return request({
    url: `/v6/user/referrerEntering`,
    method: 'get',
    params:data
  })
}

