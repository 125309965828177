<template>
    <div>
    <div class="header_clone1" v-if="bgcShow"  @click="stepClone">
            <div><img src="../../assets/cloneIcon.png" /></div>
            <div>返回</div>
        </div>
        <div class="header_clone" v-else @click="stepClone">
            <div><img src="../../assets/cloneBtn.png" /></div>
            <div>返回</div>
        </div>
        
    </div>
</template>
<script>
    export default {
        props: {
            bgcShow:{
                type: Boolean,
            }
        },
        methods:{
            // 页面回退
            stepClone(){
                this.$router.go(-1)
            }
        }
    }
</script>
<style>
     .header_clone{
        width:200px;
        display: flex;
        color: #fff;
        font-size: 30px;
        margin: 0px 0 0 40px;
        vertical-align: middle;
        height: 35px
    }
    .header_clone1{
        width:200px;
        display: flex;
        color: #000;
        font-size: 30px;
        margin: 0px 0 0 40px;
        vertical-align: middle;
        height: 35px
    }
    .header_clone1 img{
        width:42px;
        height:35px;
        margin-right: 10px
    }
    .header_clone img{
        width:42px;
        height:35px;
        margin-right: 10px
    }
</style>

